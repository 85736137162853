<template>
  <v-row justify="end">
    <v-dialog v-model="isVisibleDialog" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="$admin.can('hub-create')"
          color="primary"
          v-bind="attrs"
          v-on="on"
        >
          {{ $t("add_new_hub") }}
        </v-btn>
      </template>
      <v-form ref="form" v-model="is_valid" lazy-validation>
        <v-card>
          <v-card-title class="pb-4">
            <span class="text-h3">{{ $t("hub") }}</span>
          </v-card-title>
          <v-card-text class="pa-0 grey lighten-4">
            <v-container>
              <v-card>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        dense
                        outlined
                        :label="$t('name_*')"
                        v-model="hub.name"
                        :rules="[rules.required]"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6">
                      <v-text-field
                        dense
                        outlined
                        :label="$t('address_*')"
                        v-model="hub.address"
                        :rules="[rules.required]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-select
                        dense
                        outlined
                        :label="$t('status_*')"
                        v-model="hub.status"
                        :items="statuses"
                        :rules="[rules.required]"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <template v-if="is_new">
                        <v-select
                          dense
                          outlined
                          :label="$t('type_*')"
                          v-model="hub.type"
                          :items="types"
                          item-text="name"
                          item-value="value"
                          :rules="[rules.required]"
                        ></v-select>
                      </template>
                    </v-col>

                    <v-col cols="12" sm="3">
                      <v-text-field
                        dense
                        outlined
                        :label="$t('email_*')"
                        v-model="hub.email"
                        :rules="[rules.email]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-text-field
                        dense
                        outlined
                        :label="$t('phone_*')"
                        v-model="hub.phone"
                        :rules="[rules.required, rules.phone]"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="3">
                      <v-autocomplete
                        dense
                        outlined
                        :label="$t('wilaya_*')"
                        v-model="hub.wilaya_id"
                        :items="wilayas"
                        item-value="id"
                        item-text="name"
                        :loading="isLoadingWilayas"
                        :rules="[rules.required]"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-autocomplete
                        dense
                        outlined
                        :label="$t('city_*')"
                        v-model="hub.city_id"
                        :items="cities"
                        item-value="id"
                        item-text="name"
                        :loading="isLoadingCities"
                        :rules="[rules.required]"
                      ></v-autocomplete>
                    </v-col>

                    <v-col cols="12" sm="6">
                      <v-text-field
                        dense
                        outlined
                        :label="$t('RC')"
                        v-model="hub.RC"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6">
                      <v-select
                        dense
                        outlined
                        :label="$t('type_RC_*')"
                        v-model="hub.type_rc"
                        :items="rcTypes"
                        item-text="name"
                        item-value="value"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <small>{{ $t("indicates_required_field") }}</small>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="closeForm()">
              {{ $t("close") }}
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="saveForm(hub)"
              :disabled="!is_valid"
            >
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>

<script>
import { validationRules } from "@/mixins/validationRules";
import { mapGetters } from "vuex";

export default {
  mixins: [validationRules],

  computed: {
    ...mapGetters({
      isLoadingWilayas: "wilayas/isLoadingWilayas",
      isLoadingCities: "cities/isLoadingCities",

      statuses: "statuses/listStatuses",
      types: "hubs/getTypes",
      wilayas: "wilayas/listWilayas",
      cities: "cities/listCities",
    }),
    isVisibleDialog: {
      get() {
        return this.$store.state.hubs.isVisibleDialog;
      },
      set(value) {
        this.$store.commit("hubs/IS_VISIBLE_DIALOG", value);
      },
    },
    hub: {
      get() {
        return this.$store.state.hubs.editedHub;
      },
      set(value) {
        this.$store.commit("hubs/EDITED_HUB", value);
      },
    },

    wilaya_id() {
      return this.hub.wilaya_id;
    },

    is_new() {
      return this.hub.id ? false : true;
    },
  },

  watch: {
    isVisibleDialog: function (value) {
      if (value == true) {
        this.$store.dispatch("wilayas/list");
        this.$store.dispatch("hubs/types");
      }
    },
    wilaya_id: function (id) {
      this.$store.dispatch("cities/list", id);
    },
  },

  data() {
    return {
      is_valid: true,
      rcTypes: [
        { id: "gros", name: this.$t("gros") },
        { id: "details", name: this.$t("details") },
      ],
    };
  },

  methods: {
    closeForm() {
      this.$refs.form.reset();
      this.$store.dispatch("hubs/closeForm");
    },
    async saveForm(hub) {
      if (this.$refs.form.validate()) {
        try {
          if (hub.id) {
            await this.$store.dispatch("hubs/update", hub);
            this.$store.dispatch(
              "alerts/success",
              this.$t("updated_successfully")
            );
          } else {
            await this.$store.dispatch("hubs/add", hub);

            this.$store.dispatch(
              "alerts/success",
              this.$t("created_successfully")
            );
          }
          this.closeForm();
        } catch (error) {
          this.$store.dispatch("alerts/error", error.response?.data?.message);
        }
      }
    },
  },
};
</script>
